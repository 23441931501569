import Layout from "../../components/layout";
import { Link } from "gatsby";
import MainContent from "../../components/maincontent";
import PageBanner from "../../components/banner/banner";
import React from "react";
import Seo from "../../components/seo";

const currentYear = "2024";
const dateRange = "October 9th - October 13th";

const IndexPage = () => (
  <Layout>
    <Seo
      title="Camp"
      description=""
      lang="en"
      meta={[
        {
          content: "God, Jesus, Gospel, Heaven, Salvation, Saved, Grace, Free, How, Mid-Acts, Romans, Charity Grace Bible Church, Illinois",
          name: "keywords"
        }
      ]}
    />

    <PageBanner
      hasImage={true}
      hasOverlay={false}
      imageUrl="/assets/images/DSC_7106.jpg"
    />

    <MainContent
      hasArticle={false}
      hasBanner={true}
    >
      <h1 className='main-header'>Charity Grace Bible Camp</h1>

      <div className="grid grid--12">
        <div className="grid--4 align--center card">
          <img src="/assets/images/camp2020.jpg" alt="A large gathering of people that attended camp in 2020" />
          <span className="caption">Camp Photo 2020</span>
        </div>

        <div className="grid--8 align--left">
          <h2>What is Charity Grace Bible Camp?</h2>

          <p>Charity Grace Bible Camp began in 2015 and is operated by Charity Grace Bible Church (Located Near Peoria, IL). The weekend camp provides an opportunity to edify the Body of Christ through preaching, singing, and spending time in fellowship. Over the course of four days, this fall camp focuses on the right Bible, the right doctrine, and the right application as we study God's Word "rightly divided." (II Tim 2:15)</p>

          <p>The camp discusses the absolutes of God&apos;s Word perfectly preserved in the English language and the practical application of grace in the life of the believer.</p>

          <Link
            to={`/camp/${currentYear}/`}
            className="button button--blue"
          >View {currentYear} Schedule
          </Link>
        </div>
      </div>

      <div className="grid grid--12">
        <div className="grid--4 align--center card">
          <img src="/assets/images/DSC_6888.jpg" alt="A group of men walk across a yard towards a building." />
          <span className="caption">Headed to the Dining Hall.</span>
        </div>

        <div className="grid--8 align--left">
          <h2>Camp Manitoumi</h2>

          <p>This year we will be returning to Camp Manitoumi. Camp Manitoumi has acres of outdoors to enjoy hiking and biking trails, giant slide and zipline, not to mention many other outdoor activities! There is a chapel on the premises where our meetings will be held, along with cozy, climate controlled cabins with bathrooms and shower. More shower houses are just a short walk away. Also located at Camp Manitoumi is a dining hall which is attached to a game room that holds a variety of indoor activities.
          </p>

          <a className="button button--blue" href="https://goo.gl/maps/73wQo7Wx3BS2" target="_blank">Directions</a>
        </div>
      </div>

      <div className="grid grid--12">

        <div className="grid--4 align--center card">
          <img src="/assets/images/DSC_6768.jpg" alt="A group of people sing together." />
          <span className="caption">Singing praises to God!</span>
        </div>

        <div className="grid--8 align--left">
          <h2>Come Join Us!</h2>
          <p>This year ({currentYear}) camp will be held {dateRange}. Cost is $140.00 per adult, $120 per children ages 6-12. Lodging is provided in the form of cabins, but please bring your own bedding and toiletries. Also don&apos;t forget your King James Bible and note-taking supplies!</p>
        </div>

      </div>

      <div className="grid grid--12">

        <div className="grid--4 align--center card">
          <img src="/assets/images/DSC_7111.jpg" alt="The sun rises over a hill." />
          <span className="caption">Online Registration</span>
        </div>

        <div className="grid--8 align--left">
          <h2>Online Registration</h2>

          <Link className="button button--blue" to="/camp/register/" target="_blank"><i className="fa fa-facebook-official" aria-hidden="true" />Register Online</Link>

          <p>Questions? Send us an email at:
            &nbsp;
            <a className="link link--blue" href="mailto:charitygracebiblechurch@gmail.com?Subject=Camp_Questions" target="_top">charitygracebiblechurch@gmail.com</a>
            &nbsp;
          or find us on
          &nbsp;
            <a className="link link--blue" href="https://www.facebook.com/groups/1805008776379670/" target="_blank" rel="noreferrer"><i className="fa fa-facebook-official" aria-hidden="true" /> Facebook</a>
          </p>
        </div>

      </div>
    </MainContent>
  </Layout>
);

export default IndexPage;
